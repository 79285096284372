// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Raleway', sans-serif !important;
  --ion-default-font: 'Raleway', sans-serif !important;

  --blue: #4d62a8;
  --deep-blue: #405189;
  --green: #0ab39c;
  --deep-green: #099885;
  --yellow: #c18f23;
  --light-yellow: #cc9c35;
  --primary: #495057;
  --deep-primary: #333;
  --white: #fff;
  --border: #ced4da;
  --light-grey: #e3e3e3;

  html {
    scroll-behavior: smooth;
  }

  .body {
    --ion-font-family: 'Raleway', sans-serif !important;
  }
}
