/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/** Common CSS **/
h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
}
h1,
h2,
h3,
h4,
h5 {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
}

@media (min-width: 991px) {
    .header {
        width: calc(100% - 17px);
    }
}

button,
li,
input,
textarea {
    outline: none !important;
}

figure {
    margin: 0;
}
.nav-pills > li + li {
    margin: 0;
}
a {
    color: #212639;
    text-decoration: none;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    background-color: #fa1a34;
}

.program_slide {
    padding-bottom: 50px;

    .swiper-pagination-bullets {
        bottom: 0;

        .swiper-pagination-bullet {
            width: 13px;
            height: 13px;
            border: solid 3px #fa1a34;
            opacity: 1;
            background: #212739;
        }
        .swiper-pagination-bullet-active {
            background-color: #fa1a34;
        }
    }
}

.sponsor_slide {
    margin-top: 50px;
}

.timer_text {
    padding-inline-start: 0;
    margin: 0;
    margin-bottom: 20px;

    li {
        display: inline-block;
        font-size: 70px;
        font-family: Arial, Helvetica, sans-serif;
        color: #fff;
        font-weight: 700;
        position: relative;
        margin: 0;
        padding: 0px 20px;

        span{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0px);
            display: block;
            font-size: 11px;
            text-transform: uppercase;
            font-weight: normal;
            letter-spacing: 1px;
        }
    }
}
@media (max-width: 525px) {
    .timer_text {
        margin-bottom: 10px;

        li {
            font-size: 50px;
            padding-bottom: 10px;
            margin-bottom: 15px;
        }
    }
}
@media (max-width: 445px) {
    .timer_text {
        li {
            font-size: 32px;
            padding: 0px 15px;
            padding-bottom: 10px;
        }
    }
}

/*** Style for Page Title for all Content Pages ***/
.c_page_title {
    position: relative;
    padding: 50px 0px;
    background-color: #212739;
    margin-top: 75px;

    .container {
        position: relative;
    }

    .bg_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0.25;
    }

    h2 {
        font-size: 2em;
        font-weight: 700;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: #fff;
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 15px;

        &::after {
            content: "";
            width: 100px;
            height: 2px;
            background-color: #fb1b35;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    ul {
        padding-inline-start: 0;
        margin: 0;

        li {
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            margin-right: 10px;

            a {
                display: block;
                color: inherit;
                position: relative;
                padding-right: 15px;
                color: #fa1a34;
                cursor: pointer;

                &::before {
                    content: "\f105";
                    font-family: "FontAwesome";
                    font-weight: normal;
                    position: absolute;
                    top: -2px;
                    right: 0;
                    color: #fb1b35;
                    font-size: 18px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
@media (max-width: 992px) {
    .c_page_title {
        padding: 30px 0px;
        margin-top: 60px;
    }
}

/** Style for Magazine page and section of homepage ***/
.magazine_wrap {
    background-color: #f5f5f5;

    .mag_inner {
        margin: 0px -10px;
    }

    .magazine_block {
        background-color: #fff;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
        margin: 0px 10px;
        margin-bottom: 25px;

        figure {
            height: 225px;
            overflow: hidden;
            width: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .mag_content {
            padding: 10px 15px;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .content_inner {
                height: 185px;
                h3 {
                    font-size: 1.5em;
                    font-weight: 700;
                    color: #fb1b35;
                    margin-bottom: 8px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
                ul {
                    padding-inline-start: 0px;
                    margin: 0;
                    margin-bottom: 10px;

                    li {
                        display: inline-block;
                        font-size: 13px;
                        color: #787878;
                        font-weight: 500;
                        margin-right: 20px;

                        ion-icon {
                            vertical-align: text-top;
                            font-size: 18px;
                            margin-right: 2px;
                        }
                    }
                }
                p {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 10px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
            }

            a {
                padding: 8px 15px;
                display: inline-block;
                width: auto;
                font-size: 12px;
                margin: 0;
                margin-bottom: 5px;
            }
        }
    }
}

@media (max-width: 767px) {
    .magazine_wrap {
        .magazine_block {
            .mag_content {
                .content_inner {
                    height: auto;
                }
            }
        }
    }
}

.container-schedule{
    .accordion{
        margin: 0;
    }
    .panel{
        margin: 0;
    }
}
.accordion .item .content.day_text{
    font-size: 16px;
    font-weight: 600;
}
